import NavLink from '@components/Common/NavLink';

import styles from './TopNav.module.css';

import Image from 'next/image';

import {languages} from '@constants/../../constants/common';
import {useRouter} from 'next/router';
import LoginButton from '@components/Common/LoginButton';

import Cookie from 'js-cookie';
import {signOut} from 'next-auth/react';
import {useTranslation} from 'next-i18next';
import {MyAccountLink, TopHeaderNavigation} from '@constants/navigations';
import {LocaleEnum} from '@enums/locale';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faAngleDown,
  faSignOutAlt,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import {useModal} from '@contexts/index';
import {ModalActionTypes} from '@contexts/actions';
import SelectionMenu from '../HamburgerMenu/SelectionMenu';
import Selection from '../HamburgerMenu/Selection';
import Link from '@components/Common/Link';
import {useState} from 'react';
import PriceListModal from '@components/PriceListModal';

export default function TopNav({
  sessionStatus,
}: {
  sessionStatus: 'authenticated' | 'loading' | 'unauthenticated';
}) {
  const router = useRouter();
  const locale = router.locale as LocaleEnum;
  const {t} = useTranslation('common');

  const [openPriceModal, setOpenPriceModal] = useState<boolean>(false);

  const {dispatch} = useModal();
  const showLoginModal = () =>
    dispatch({type: ModalActionTypes.TOGGLE_LOGIN_MODAL});

  const switchLocale = (locale: LocaleEnum) => {
    Cookie.set('NEXT_LOCALE', locale);
  };

  return (
    <>
      <div className={`${styles.topNav}`}>
        <div className="container-md">
          <div className="row">
            <div className={` col-6 col-sm-7 col-md-8 ${styles.navFull}`}>
              <div className={`${styles.topNavLeft}`}>
                <ul className="top-nav-menu mb-0">
                  <div className={'d-none d-lg-inline-block'}>
                    <>
                      {TopHeaderNavigation[locale].map((item, index) => {
                        return (
                          <NavLink
                            key={index}
                            href={item.link}
                            classNames={styles.topNavItem}
                          >
                            {item.name}
                          </NavLink>
                        );
                      })}
                    </>
                  </div>
                  <Selection
                    activeClass={styles.topNavSelectionActive}
                    classNames={`${styles.topNavItem} d-inline-block d-lg-none ${styles.topNavSelection}`}
                  >
                    <Link variant={'buttonLink'} locale={locale}>
                      <span>{t<string>('footer.quick_links.header')}</span>{' '}
                      <FontAwesomeIcon className={'ms-1'} icon={faAngleDown} />
                    </Link>
                    <SelectionMenu classNames={`${styles.topNavSelectionMenu}`}>
                      {TopHeaderNavigation[locale].map((item, index) => (
                        <NavLink
                          classNames={styles.quickLink}
                          key={index}
                          href={item.link}
                        >
                          {item.name}
                        </NavLink>
                      ))}
                    </SelectionMenu>
                  </Selection>
                  {sessionStatus === 'authenticated' ? (
                    <>
                      <NavLink
                        href={`${MyAccountLink[locale].link}`}
                        classNames={`${styles.topNavItem} d-none d-sm-inline-block`}
                      >
                        <span className={'d-none d-md-inline-block'}>
                          {MyAccountLink[locale].name}
                        </span>
                        <FontAwesomeIcon className={'ms-2'} icon={faUser} />
                      </NavLink>
                      <NavLink
                        onClick={() =>
                          signOut({redirect: true, callbackUrl: '/'})
                        }
                        variant={'buttonLink'}
                        classNames={`${styles.topNavItem} d-sm-inline-block`}
                      >
                        <span className={'d-none d-md-inline-block'}>
                          {' '}
                          {t<string>('top_nav.sign_out')}
                        </span>
                        <FontAwesomeIcon
                          className={'ms-2'}
                          icon={faSignOutAlt}
                        />
                      </NavLink>
                    </>
                  ) : (
                    <>
                      <LoginButton>
                        <NavLink
                          variant={'buttonLink'}
                          classNames={styles.topNavItem}
                          onClick={() => showLoginModal()}
                        >
                          <span className={'d-none d-sm-inline-block'}>
                            {t<string>('top_nav.sign_in')}
                          </span>
                          <FontAwesomeIcon className={'ms-2'} icon={faUser} />
                        </NavLink>
                      </LoginButton>
                    </>
                  )}
                </ul>
              </div>
            </div>
            <div className={`col-6 col-sm-5 col-md-4 ${styles.navFull}`}>
              <div className={`${styles.topNavRight} text-end`}>
                <ul className="top-nav-menu mb-0">
                  <NavLink
                    classNames={styles.topNavItem}
                    variant={'buttonLink'}
                    onClick={() => setOpenPriceModal(true)}
                  >
                    {t<string>('top_nav.price_list')}
                  </NavLink>
                  {languages.map((lang, index) => (
                    <NavLink
                      classNames={styles.topNavItem}
                      href={'/'}
                      onClick={() => switchLocale(lang.locale as LocaleEnum)}
                      locale={lang.locale}
                      key={index}
                    >
                      <Image
                        alt={`${lang.locale} Language`}
                        src={lang.flag}
                        height={15}
                        width={20}
                      />
                    </NavLink>
                  ))}
                  <NavLink
                    classNames={styles.topNavItem}
                    href="https://www.liunedoor.nl"
                  >
                    <Image
                      alt="Dutch Language"
                      src="https://assets.liunedoor.com/upload/the-netherlands-flag.webp"
                      height={15}
                      width={20}
                    />
                  </NavLink>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PriceListModal
        openModal={openPriceModal}
        setOpenModal={setOpenPriceModal}
      />
    </>
  );
}
